import React from 'react';

const BtnOrangeSm =({title, onClick}) => {

    return (
        <div className="bg-naranja  p-1 button_white mr-2 cursor w-100 my-1" onClick={onClick}>
            <div className=" px-4 py-2 border-white-30 text-center w-100 text-white">
                {title}
            </div>
        </div>
    )
  
}

export default BtnOrangeSm;