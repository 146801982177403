import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ItemReview = ({ item }) => {
    let { product } = item
    return (
        <tr>
            <td>{product.name}</td>
            <td align='right'>{item.amount}</td>
            <td align='right'>{formatNumber(item.cost)}</td>
            <td align='right'>{formatNumber(item.cost * item.amount)}</td>
        </tr>
    )
}

export default ItemReview;