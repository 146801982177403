import React, { Fragment } from 'react'

const BlueTitleBack = ({ title }) => {
    return (
        <Fragment>
            <div className="text-center pt-2  w-100">
                <span className="px-2 font-title align-middle text-uppercase font-15">{title}</span>
            </div>
        </Fragment>
    )
}
export default BlueTitleBack;